// @flow
import React, { Component } from 'react';
import _ from 'lodash';
// Composers
import { connect } from 'react-redux';
import { employeeConnector } from '../employee';
import { printerConnector } from '../printer';
import { distributionCenterConnector } from '../configurationModule';
// Components
import { Redirect } from 'react-router-dom';
import LineItem from '../components/LineItem';
import Footer from '../components/Footer';
import ErrorModal from '../components/ErrorModal';
import CompletionModal from '../components/CompletionModal';
import Loader from '../components/Loader';
import PeriodicGetter from '../components/PeriodicGetter';
import colorBarcodes from '../constants/colorBarcodes';
import OptionsComponent from '../components/OptionsComponent';
import OrderItem from '../components/OrderItem';
// Selectors
import {
  getCurrentOrderNumberSelector,
  getCurrentOrderLineItemsSelector,
  gettingOrderLoadingSelector,
  getCurrentOrderStateSelector,
  currentOrderHasIssuesSelector,
  currentOrderListSelector,
  hasOrderInCurrentOrderListSelector,
  gettingOrdersLoadingSelector,
  updatingAnyLineItemLoadingSelector,
  requestingXmlLoadingSelector,
  getCurrentOrderObservationSelector,
  currentOrderSelector,
  issuesByLineItemIdSelector,
} from '../selectors/orders';
// Actions
import {
  editLineItem,
  startPackaging,
  findOrders,
  finishPackaging,
  downloadSalesXml,
} from '../actions/orders';
// Types
import type { LineItem as LineItemType, OrderAasm, Order, Mix } from '../types';
// Styles
import './PackagingPage.css';
// Utility Functions
import randomColor from 'randomcolor';

type Props = {
  orderNumber: string,
  orderLineItems: LineItemType[],
  orderObservation: string,
  isGettingOrder: boolean,
  isGettingOrders: boolean,
  hasIssues: boolean,
  requestingXmlLoading: boolean,
  mix: Mix,
  currentOrder: Order,
  handleOrderUpdateLineItem: (lineItemInformation: any) => void,
  handleQueueTransition: (number: string, state: OrderAasm) => void,
  handleGetOrders: () => void,
  downloadSalesXml: (orderNumber: string) => void,
  // From employee connector
  employeeLoggedIn: boolean,
  employeeUsername: string,
  employeeLoggingInLoading: boolean,
  employeeValidatingLoading: boolean,
  employeeHasRole: (role: string) => boolean,
  // From Printer Connector
  handlePrintOrderLabel: ({ number: string, numberOfBoxes: number }) => void,
  handlePrintProductLabel: ({
    name: string,
    sku: string,
    quantity: number,
    unit: string,
  }) => void,
  // From distributionCenterConnector
  distributionCenter: string,
  distributionCenterLoading: boolean,
};

type State = {
  inActiveOrder: boolean,
  errorSelectionModalIsOpen: boolean,
  completionModalIsOpen: boolean,
  lineItemId: ?number,
};

class PackagingPage extends Component {
  state: State;
  processBarcode: (barcode: string) => void;

  constructor(props: Props) {
    super(props);

    this.colors = randomColor({
      count: 1000,
      seed: 4,
      hue: 'random',
    });

    this.state = {
      inActiveOrder: false,
      errorSelectionModalIsOpen: false,
      completionModalIsOpen: false,
      lineItemId: null, // For adding error to in the error selection modal
    };
  }

  addLineItemError(id: number) {
    this.setState({ errorSelectionModalIsOpen: true, lineItemId: id });
  }

  // Erases the associated packaging quantities for the given line item
  // This is used by the lineItem::handleClear
  clearLineItem(id: number) {
    this.props.handleOrderUpdateLineItem({
      id: id,
      quantityRevised: 0,
    });
  }

  processBarcode(barcode: string) {
    // TODO: If there is 204 we will end up in an order that has no order number or might not exist.
    // In that case we should be redirected back to the previous page/state?

    // If the barcode scanned matches the number of one of the orders
    const activeOrder = _.find(this.props.orders, { number: barcode });
    if (activeOrder) {
      // We found a matching order
      //validate will be true if is not one active order OR if the employee confirm modification
      let validate = !this.state.inActiveOrder;
      if (this.state.inActiveOrder) {
        //if it is on active order, it's necessary to check the confirmation of employee
        const confirmation = window.confirm(
          `Tem certeza que quer trocar de pedido? 
          Essa mudança fará com que o pedido ${this.props.orderNumber} 
          seja encaminhado para o estado de erro.`,
        );

        validate = confirmation;
      }
      if (validate) {
        this.props.handleQueueTransition(barcode);
      }
      this.setState({ inActiveOrder: true });
    }

    // If there is an active order
    if (this.state.inActiveOrder) {
      // If the barcode refers to a product
      if (barcode[0] === '2') {
        // Check if the sku matches that of the current product
        const barcodeSku = barcode.slice(1, 7);
        const barcodeQuantity = parseInt(barcode.slice(7, 12), 10);

        const activeLineItem = _.find(this.props.orderLineItems, {
          sku: barcodeSku,
        });

        if (!activeLineItem) {
          return;
        }

        this.props.handleOrderUpdateLineItem({
          id: activeLineItem.id,
          quantityRevised: activeLineItem.quantityRevised + barcodeQuantity,
        });

        return;

        // CUSTOM a printed barcode with lineItemId
      } else if (barcode[0] === '3' || barcode[0] === '4') {
        // Check if the id matches that of the current product

        let barcodeId: number;
        let barcodeByIdQuantity: number;

        if (barcode[0] === '3') {
          barcodeId = parseInt(barcode.slice(1, 7), 10);
          barcodeByIdQuantity = parseInt(barcode.slice(7, 12), 10);
        } else {
          barcodeId = parseInt(barcode.slice(1, 8), 10);
          barcodeByIdQuantity = parseInt(barcode.slice(8, 13), 10);
        }

        const activeLineItemById = _.find(this.props.orderLineItems, {
          id: barcodeId,
        });

        if (!activeLineItemById) {
          return;
        }

        this.props.handleOrderUpdateLineItem({
          id: activeLineItemById.id,
          quantityRevised:
            activeLineItemById.quantityRevised + barcodeByIdQuantity,
        });
      }
      // Related to closing order

      if (barcode === colorBarcodes.pink) {
        this.setState({ completionModalIsOpen: true });
        return; //exists
      }

      // If we have read the YELLOW colorcode we should download the XML for NFE emission.
      // This should only execute if we have an active order
      if (barcode === colorBarcodes.yellow && this.state.inActiveOrder) {
        this.props.handleDownloadSalesXml(this.props.orderNumber);

        return;
      }
    }

    // REFRESH Triggers a get line item request to refresh the page
    if (barcode === colorBarcodes.blue) {
      this.props.handleGetOrders();

      return;
    }

    // LOGOUT Logs out the current user
    if (barcode === colorBarcodes.black) {
      // Programatically redirect to the logou page
      this.props.history.push('/logout?to=packaging');

      return;
    }
  }

  render() {
    // If the user is not logged in we should show the login page
    if (!this.props.employeeLoggedIn) {
      return <Redirect to="/login?to=packaging" />;
    }

    // Show list of all orders
    if (!this.state.inActiveOrder) {
      // If we are NOT in an active order
      const orders = _.sortBy(this.props.orders, ['state', 'routeId']);
      const routeIds = _.uniq(_.map(this.props.orders, 'routeId'));
      return (
        <div>
          <table className="packaging-page-order-table">
            <tr>
              <th>Número da rota</th>
              <th>Número do pedido</th>
              <th>Prazo</th>
              <th>Última atualização</th>
              <th>Quantidade de Caixas</th>
              <th>Estado</th>
            </tr>
            {orders.map((order, index) => (
              <OrderItem
                key={index}
                color={this.colors[routeIds.indexOf(order.routeId)]}
                order={order}
              />
            ))}
          </table>
          <PeriodicGetter
            loading={this.props.isGettingOrders}
            handleGet={this.props.handleGetOrders}
          />

          <Footer handleSubmit={this.processBarcode.bind(this)} />
        </div>
      );
    }

    // If we are getting a specific order. Usually after one is beeped
    if (this.props.isGettingOrder) {
      return <Loader />;
    }

    let showPaymentWarning = false;
    
    if (this.props.currentOrder && this.props.currentOrder.payments) {
      const currentOrderUnpaidPixPayment = this.props.currentOrder.payments.find(
        (payment) =>
          payment.sourceType === 'PixPayment' && payment.state === 'pending',
      );

      showPaymentWarning = !!currentOrderUnpaidPixPayment;
    }

    return (
      <div className="">
        <ErrorModal
          lineItemId={this.state.lineItemId}
          isOpened={this.state.errorSelectionModalIsOpen}
          closeModal={() => {
            this.setState.bind(this)({ errorSelectionModalIsOpen: false });
          }}
        />
        <CompletionModal
          isOpened={this.state.completionModalIsOpen}
          orderNumber={this.props.orderNumber}
          username={this.props.employeeUsername}
          hasIssues={this.props.hasIssues}
          handleSubmitOrder={this.props.handleEditOrderPackaging}
          handlePrintOrderLabel={this.props.handlePrintOrderLabel}
          closeModal={() =>
            this.setState.bind(this)({ completionModalIsOpen: false })
          }
          completeOrder={() => {
            this.setState.bind(this)({ inActiveOrder: false });
            this.props.handleGetOrders();
          }}
          shouldPrint={false}
        />

        {this.props.isGettingOrder || this.props.requestingXmlLoading ? (
          <Loader />
        ) : (
          <div>
            {this.props.orderLineItems.length > 0 ? (
              <div>
                <OptionsComponent
                  weightLimited={this.props.currentOrder.weightLimited}
                  showPaymentWarning={showPaymentWarning}
                  shouldPrintSaleReceipt={
                    this.props.currentOrder.shouldPrintSaleReceipt
                  }
                  mixes={this.props.currentOrder.mixes}
                  orderObservation={this.props.orderObservation}
                />

                {this.props.orderLineItems.map((l, ind) => {
                  const mix = _.filter(
                    this.props.currentOrder.mixes,
                    function (o) {
                      return o.id === l.mixId;
                    },
                  ).pop();
                  const mixIndex = _.findIndex(
                    this.props.currentOrder.mixes,
                    mix,
                  );

                  return (
                    <LineItem
                      key={ind}
                      canEditError={true} // TODO
                      issues={this.props.issuesByLineItem(l.id)}
                      isComplete={
                        l.quantityActual === l.quantityRevised &&
                        l.quantityRevised !== 0
                      }
                      imageUrl={l.imageUrl}
                      sku={l.sku}
                      name={l.name}
                      optionValues={
                        l.options
                          ? l.options.map((o) => (o ? o.value : ''))
                          : []
                      }
                      quantityOrdered={l.quantityOrdered}
                      quantityActual={l.quantityActual}
                      quantityUnit={l.quantityUnit}
                      quantityRevised={l.quantityRevised}
                      handleAddError={() =>
                        this.addLineItemError.bind(this)(l.id)
                      }
                      preparationImages={l.preparationImages}
                      handleClear={() => this.clearLineItem.bind(this)(l.id)}
                      portioningSize={l.portioningSize}
                      mix={mix}
                      mixIndex={mixIndex}
                      observation={l.observation}
                    />
                  );
                })}
              </div>
            ) : (
              <div className="packaging-box">
                <div className="packaging-box-text">Sem produtos...</div>
              </div>
            )}
          </div>
        )}
        <Footer handleSubmit={this.processBarcode.bind(this)} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps: {}) => {
  return {
    orderNumber: getCurrentOrderNumberSelector(state),
    orderLineItems: getCurrentOrderLineItemsSelector(state),
    // TODO: Decouple these loading states so they can be shown differently.
    isGettingOrder:
      gettingOrderLoadingSelector(state) ||
      updatingAnyLineItemLoadingSelector(state),
    orderAasm: getCurrentOrderStateSelector(state),
    hasIssues: currentOrderHasIssuesSelector(state),

    orders: currentOrderListSelector(state),
    hasOrders: hasOrderInCurrentOrderListSelector(state),
    isGettingOrders: gettingOrdersLoadingSelector(state),
    currentOrder: currentOrderSelector(state),
    issuesByLineItem: (itemId: number) =>
      issuesByLineItemIdSelector(state, itemId),

    requestingXmlLoading: requestingXmlLoadingSelector(state),
    orderObservation: getCurrentOrderObservationSelector(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleOrderUpdateLineItem: (lineItem) => {
      dispatch(editLineItem(lineItem));
    },
    handleQueueTransition: (number) => {
      dispatch(startPackaging(number));
    },
    handleGetOrders: () => {
      dispatch(
        findOrders({
          packaging: true,
          byDistributionCenter: ownProps.distributionCenter,
        }),
      );
    },
    handleEditOrderPackaging: (orderNumber: string, boxesCount: number) => {
      dispatch(finishPackaging(orderNumber, boxesCount));
    },
    handleDownloadSalesXml: (orderNumber: string) => {
      dispatch(downloadSalesXml(orderNumber));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default distributionCenterConnector(
  printerConnector(employeeConnector(connector(PackagingPage))),
);
